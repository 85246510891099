import React from 'react'

import "web_common/css/LimnTech.css"

interface ApiErrorProps {

}

const ApiError: React.FC<ApiErrorProps> = (props) => {
    return (
        <div>
            <h1 className='limn-warning limn-header'> An Error Has Occurred </h1>
            <p className='limn-tiny-p'> An error occurred making your request. Try and refresh the page. If you continue to experience problems, please contact the LimnTech team. </p>
        </div>
    )
}

export default ApiError;
